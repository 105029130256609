<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:270px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">安全中心</p>
            <div>
                <table style="width: 100%; text-align: center; margin-top: 8px; line-height: 55px;">
                    <tr>
                        <th>商户秘钥</th>
                    </tr>
                    <tr>
                        <th>{{total.value1}}
                            <button @click="stochastic()">随机生成</button>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            total:{
                value1: 0,
            },
        }
    },
    created(){
        this.getTotal();
    },
    methods:{
        stochastic(){
            _api.get(apiurl.stochastic,{
            }).then(res=>{
                this.total.value1=res.data.value1
            })
        },
        getTotal(){
            this.loading = true
            _api.get(apiurl.safe,{
            }).then(res=>{
                this.loading = false
                this.total.value1=res.data.value1
            })
        }
    }
}
</script>
<style scoped>

</style>